/* @config "../tailwind.config.ts"; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap");

html,
body,
#root {
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

html {
	font-size: 16px;
}

body {
	margin: 0;
	font-family: "Chivo", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
